<template>
  <v-container
    id="prase-log"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="11"
      >
        <base-material-card
          color="primary"
        >
          <template v-slot:heading>
            <div
            class="text-h3 font-weight-light"
            >
              <span>Log</span>
              <v-btn
              id="refresh"
              ref="refreshBtn"
              class="ml-2"
              icon
              @click="logClick"
              >
              <v-icon>
                mdi-autorenew
              </v-icon>
              </v-btn>
            </div>
          </template>
          <div class="log-content">
            <div
              v-for="(item, index) in currentLog.split('\r\n')"
              :key="index"
            >
              {{ item }}
            </div>
          </div>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import api from '@/utils/api.js'

  export default {
    //
    data () {
      return {
        // loading: false,
        userId: null,
        currentLog: '',
      }
    },
    created () {
      this.userId = localStorage.getItem('taskId')
    },
    methods: {
      updateLog () {
        // this.loading = true
        api.getLog(this.userId).then((res) => {
          if (res.status === 200) {
            const resData = res.data
            const success = resData.success
            const logData = resData.data
            if (success) {
              this.currentLog = logData
            }
          }
          // this.loading = false
        })
      },
      logClick () {
        this.$refs.refreshBtn.loading = true
        // this.loading = true
        api.getLog(this.userId).then((res) => {
          if (res.status === 200) {
            const resData = res.data
            const success = resData.success
            const logData = resData.data
            if (success) {
              this.currentLog = logData
            }
          }
          this.$refs.refreshBtn.loading = false
          // this.loading = false
        })
      },
    },

    mounted () {
      this.updateLog()
      setInterval(() => {
        this.updateLog()
      }, 6000)
    },
  }
</script>

<style lang="css" scoped>
  #refresh{
    float: right;
    background-color: white;
    color: blue;
    /* position: absolute; */
    /* margin: 10px 500px 10px 100px; */
    /* border: 1.5px solid gray; */
  }
  .log-content {
    margin: 0px 0px;
    height: 500px;
    max-height: 500px;
    padding: 10px 10px;
    overflow-y: auto;
    overflow-x: hidden;
/*    border: 1.5px solid gray;
    border-radius: 5px; */
  }
</style>
